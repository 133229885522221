import { DirectUpload } from "@rails/activestorage"
import  FeedbackManager from "./upload_feedback_manager"
import { destroy } from "@rails/request.js"

export default class {
  #element
  #fileInput
  #feedbackManager

  constructor(element, fileInput) {
    this.#element = element
    this.#fileInput = fileInput
    this.#feedbackManager = new FeedbackManager(this.#element.parentNode)
  }

  uploadImage(file) {
    this.#feedbackManager.appendSpinner()
    const url = this.#fileInput.dataset.directUploadUrl
    const upload = new DirectUpload(file, url, this)
    upload.create((error, blob) => {
      if(error) {
        console.log(error)
      } else {
        this.#appendFileIdToForm(blob.signed_id)
        this.#element.setAttribute('data-blob-id', blob.signed_id)
        this.#fileInput.value = null
        this.#feedbackManager.removeSpinner()
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress',
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    this.#feedbackManager.updateProgress((event.loaded * 100 / event.total).toFixed(1))
  }

  async deleteImage() {
    if(this.#element.dataset.filePersisted === 'true') {
    } else {
      const blobId = this.#element.dataset.blobId
      const destroyPath = `/storage/${blobId}`
      const response = await destroy(destroyPath, {})
      if(response.ok) {
        this.#element.querySelector(`input[name='${this.#fileInput.name}'][type=hidden]`)?.remove()
        this.#element.removeAttribute('data-blob-id')
      }
    }
  }

  #appendFileIdToForm(blobId) {
    const hiddenField = document.createElement('input')
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("value", blobId)
    hiddenField.name = this.#fileInput.name
    this.#element.appendChild(hiddenField)
  }
}
