import  Toast from './toast'

export default class {
  constructor(guaranteeDepositFields, controller, globalApp) {
    this.guaranteeDepositFields = guaranteeDepositFields
    this._controller = controller
    this._application = globalApp
    this.branchOfficeId = this.findElement('#branch-office-id').dataset.branchOfficeId
    this.weeklyPaymentField = this.findElement('input[name="quotation[weekly_payment]"]')
    this.guaranteeDepositField = this.findElement('input[name="quotation[guarantee_deposit]"]')
    this.bindFields()
  }

  bindFields() {
    this.guaranteeDepositFields.forEach(el => {
      el.addEventListener('keypress', event => { this.setupWriteTimer(event) })
    })
  }

  setupWriteTimer(event) {
    if (this.writeTimer) { clearTimeout(this.writeTimer) }

    event.target.style.cursor = 'progres'
    this.showSpinner()
    this.writeTimer = setTimeout(() => { this.sendRequest(event) }, 2000)
  }

  findElement(selector) {
    return document.querySelector(selector)
  }

  get controllerInstance() {
    return this._application.getControllerForElementAndIdentifier(
      this._controller, this._controller.dataset.controller
    )
  }

  sendRequest(event) {
    const guaranteeDeposit = parseFloat(event.target.value.replace(',', ''))
    const params = this.prepareParams(event)

    if(guaranteeDeposit < 12000) {
      this.renderError('El pago inicial debe ser mayor')
    } else {
      fetch(`/pricing/lists?${params}`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          if(data.errors) throw new Error(data.errors)

          this.updateLabel(data, event)
        })
        .catch(error => {
          this.renderError(error)
          this.removeSpinner()
        })
    }
  }

  prepareParams(event) {
    const searchCriteria = this.controllerInstance.searchCriteria
    const guaranteeDeposit = parseFloat(event.target.value.replace(',', ''))
    const gnvSystem = searchCriteria.get('fuel') == 'gnv' ? true : false
    const params = new URLSearchParams()

    params.append('periods', searchCriteria.get('periods') || 36)
    params.append('guarantee_deposit', guaranteeDeposit)
    params.append('car_model', searchCriteria.get('model').replace(/\_/g, ' '))
    params.append('car_make', searchCriteria.get('brand').replace(/\_/g, ' '))
    params.append('car_version', searchCriteria.get('version').replace(/\_/g, ' '))
    params.append('year', searchCriteria.get('year'))
    params.append('gnv_system', gnvSystem)
    params.append('branch_office_id', this.branchOfficeId)
    return params
  }

  updateLabel(data, event) {
    const activeTab = this._controller.querySelector('.box-cotizacion-item > .tab-pane.active')
    const priceLabel = activeTab.querySelector('.price-label')
    const payment = parseFloat(data.pricing.periodic_repayment)
    const currencyFormat =  { style: 'currency', currency: 'USD', minimumSignificantDigits: 1 }
    const formatedPayment = payment.toLocaleString('en-US', currencyFormat)

    priceLabel.textContent = `${formatedPayment}`
    this.weeklyPaymentField.value = data.pricing.periodic_repayment
    this.guaranteeDepositField.value = parseFloat(event.target.value.replace(',', ''))
    this.controllerInstance.updateSearchCriteria('new_payment', payment)
    this.controllerInstance.updateSearchCriteria('guarantee_deposit', parseFloat(event.target.value.replace(',', '')))
    this.removeSpinner()
    this.notifyPaymentChanged(payment, parseFloat(event.target.value.replace(',', '')), this.branchOfficeId)
  }

  showSpinner() {
    const activeTab = this._controller.querySelector('.box-cotizacion-item > .tab-pane.active')
    const paymentContainer = activeTab.querySelector('.payment-container')
    const spinnerRunning = paymentContainer.querySelector('#spinner-loader')
    if(!spinnerRunning) {
      const spinner = this.createSpinner()
      paymentContainer.appendChild(spinner)
    }
  }

  removeSpinner() {
    const activeTab = this._controller.querySelector('.box-cotizacion-item > .tab-pane.active')
    const paymentContainer = activeTab.querySelector('.payment-container')
    const target = paymentContainer.querySelector('#spinner-loader')
    target.remove()
  }

  createSpinner() {
    const element = document.createElement('div')
    element.role = 'status'
    element.id = 'spinner-loader'
    element.classList.add('spinner-border')
    element.style.width = '1rem'
    element.style.height = '1rem'
    element.style.opacity = '0.6'
    element.style.fontSize = '11px'
    return element
  }

  renderError(msg = '', error) {
    let message = msg === '' ? 'No se encontro el producto' : msg
    const errorMessage = new Toast(message, true)
    errorMessage.show()
  }

  notifyPaymentChanged(weeklyPayment, depositGuarantee, branchOfficeId) {
    const customEvent = new CustomEvent('pricing:updated', {
      bubbles: true,
      detail: {
        guaranteeDeposit: depositGuarantee,
        weeklyPayment: weeklyPayment,
      }
  });
    document.body.dispatchEvent(customEvent)
  }
}
