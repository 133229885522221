import { Controller } from "@hotwired/stimulus"
import { Collapse } from "bootstrap"

export default class extends Controller {
  static targets = []

  connect() {
    this.collapsable = new Collapse("#commentCollapse", { toggle: false })
  }

  toggleSection(event) {
    const button = event.currentTarget
    button.classList.toggle('collapsed')
    this.collapsable.toggle()
  }
}
